<template>
  <b-container class="p-5">
    <div class="text-center">
      <b-img
        :src="appLogoImage"
        alt="logo"
      />
    </div>
    <b-card
      no-body
      class="p-2"
    >
      <b-form-group v-show="digitalRecordData.numeroPedimento">
        <b-card-body
          v-if="language == 'es-MX'"
          class="text-center"
        >
          <b-row>
            <b-col
              offset-md="5"
              md="4"
            >
              <ul class="text-left">
                <h3>Expediente Digital de,</h3><br>
                <h3>Pedimento <b-badge>{{ digitalRecordData.numeroPedimento }}</b-badge></h3>
                <h3>Clave De Documento <b-badge>{{ digitalRecordData.claveDocumento }}</b-badge></h3>
                <h3>Aduana <b-badge>{{ digitalRecordData.aduana }}</b-badge> </h3>
                <h3>Patente <b-badge>{{ digitalRecordData.patente }}</b-badge></h3>
              </ul>
            </b-col>
          </b-row>
        </b-card-body>
        <b-card-body
          v-if="language == 'en-US'"
          class="text-center"
        >
          <b-row>
            <b-col
              offset-md="5"
              md="4"
            >
              <ul class="text-left">
                <h3>Digital Record for,</h3><br>
                <h3>Pedimento <b-badge>{{ digitalRecordData.numeroPedimento }}</b-badge></h3>
                <h3>Document Code <b-badge>{{ digitalRecordData.claveDocumento }}</b-badge></h3>
                <h3>Customs <b-badge>{{ digitalRecordData.aduana }}</b-badge> </h3>
                <h3>Patent <b-badge>{{ digitalRecordData.patente }}</b-badge></h3>
              </ul>
            </b-col>
          </b-row>
        </b-card-body>
        <div class="text-center">
          <b-button
            variant="success"
            @click="downloadDigitalRecord()"
          >
            <b-spinner
              v-if="downloading"
              small
              type="grow"
            />
            {{ language == 'en-US' ? 'Download' : 'Descarga' }}
            <feather-icon
              icon="DownloadCloudIcon"
            />
          </b-button>
        </div>
      </b-form-group>
    </b-card>
  </b-container>

</template>

<script>
import {
  BContainer,
  BImg,
  BCard,
  BFormGroup,
  BCardBody,
  BButton,
  BSpinner,
  BRow,
  BCol,
  BBadge,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import router from '@/router'
import { saveAs } from 'file-saver'
import { $themeConfig } from '@themeConfig'
import digitalrecordService from '../../../services/digitalrecord.service'

export default {
  components: {
    BContainer,
    BImg,
    BCard,
    BFormGroup,
    BCardBody,
    BButton,
    BSpinner,
    BRow,
    BCol,
    BBadge,
  },
  setup() {
    const downloading = ref(false)
    const digitalRecordData = ref({})
    const language = router.currentRoute.params.nombre
    const {
      fetchOperationsGuid,
      fetchOperationsDownloadUuid,
    } = digitalrecordService()

    if (router.currentRoute.params.uuid) {
      fetchOperationsGuid(router.currentRoute.params.uuid, data => {
        digitalRecordData.value = data
      })
    }

    const downloadDigitalRecord = () => {
      downloading.value = true
      fetchOperationsDownloadUuid(router.currentRoute.params.uuid, data => {
        downloading.value = false
        const file = new File([data], `ED_NumeroReferencia_${digitalRecordData.value.numeroReferencia}.zip`)
        saveAs(file, `ED_NumeroReferencia_${digitalRecordData.value.numeroReferencia}.zip`)
      })
    }

    const { appLogoImage } = $themeConfig.app
    return {
      appLogoImage,
      digitalRecordData,
      downloadDigitalRecord,
      language,
      downloading,
    }
  },
}
</script>
<style>
</style>
